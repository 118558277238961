
import { Options, Vue } from 'vue-class-component';
import { CompanyClient } from '@/services/Services';
import editCompanyModal from '../modals/editCompanyModal.vue';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class Companies extends Vue {

    items: OM.Company[] = [];
    loaded: boolean = false;

    created() {
        this.init();
    }

    init() {
        CompanyClient.getAll()
        .then(x => {
            this.items = x;
            this.loaded = true;
        })
    }

    editCompany(item: OM.Company) {
        this.$opModal.show(editCompanyModal, {
            company: item,
        }, () => this.init())
    }

    resetApiKey(item: OM.Company) {

        var ok = confirm("Sei sicuro di resettare?"); 
        if(ok){
            CompanyClient.refreshApiKey(item.identifier)
            .then(x => {
                var index = this.items.findIndex(x => x.identifier == item.identifier);
                this.items[index] = x; 
                location.reload()
            })
            
        }

    }

}
